var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DashboardWidget",
    { attrs: { widget: _vm.widget, enabled: _vm.enabled, cls: _vm.def.cls } },
    [
      _c(
        "v-card",
        {
          staticClass: "my-0 mx-0 align-center",
          class: _vm.def.cls,
          staticStyle: { height: "100%" },
          attrs: { flat: "", dense: "" },
        },
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.def.title))]),
          _c(
            "v-card-text",
            { staticClass: "px-1" },
            [
              _c(
                "v-data-table",
                _vm._g(
                  {
                    attrs: {
                      dense: "",
                      "disable-sort": "",
                      options: _vm.options,
                      "item-class": _vm.itemClass,
                      "sort-by": _vm.def.sort_by,
                      "sort-desc": true,
                      "footer-props": _vm.footerProps,
                      "hide-default-footer": "",
                      headers: _vm.def.headers,
                      items: _vm.def.items,
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.iLoading
                          ? {
                              key: "item",
                              fn: function () {
                                return [
                                  _c(
                                    "tr",
                                    { staticClass: "table-item" },
                                    _vm._l(_vm.headers, function (header) {
                                      return _c(
                                        "td",
                                        {
                                          key: header.value,
                                          class: `text-${header.align}`,
                                        },
                                        [
                                          _c("v-skeleton-loader", {
                                            attrs: { type: "text" },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        _vm._l(_vm.def.headers, function (form) {
                          return {
                            key: `item.${form.value}`,
                            fn: function ({ item }) {
                              return [
                                form.shorten
                                  ? [
                                      _c(
                                        "v-tooltip",
                                        {
                                          key: `${form.value}-shorten`,
                                          attrs: {
                                            "open-delay": 250,
                                            disabled: !_vm.shouldShortenString(
                                              item[form.value],
                                              "e",
                                              form.shorten
                                            ),
                                            top: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "ellipsis"
                                                              )(
                                                                item[
                                                                  form.value
                                                                ],
                                                                "e",
                                                                ".",
                                                                form.shorten
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item[form.value])),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  : _c(
                                      "span",
                                      { key: `${form.value}-noshorten` },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              form.formatter
                                                ? form.formatter(
                                                    item[form.value]
                                                  )
                                                : item[form.value]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ]
                            },
                          }
                        }),
                        {
                          key: `item.no`,
                          fn: function ({ index }) {
                            return [_vm._v(" " + _vm._s(index + 1) + " ")]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  _vm._on
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }