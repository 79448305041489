<template>
	<DashboardWidget
		@widgetResize="onResize"
		:widget="widget"
		:enabled="enabled"
		v-slot="{ cls }">
		<v-card
			flat
			class="my-0 mx-0"
			style="height: 100%"
			:class="cls">
			<div :style="`height: 90%`">
				<v-card-title class="pt-0">
					<v-switch
						v-if="switcherTitle"
						v-model="dataIndex"
						:trueValue="1"
						:false-value="0"
						class="mt-0"
						hide-details></v-switch>
					<div class="ml-2 mt-1">
						{{ switcherTitle ? def.title[dataIndex] : def.title }}
					</div>
					<template v-if="showTypeSwitch">
						<v-spacer></v-spacer>
						<v-sheet
							:width="90"
							class="pa-1"
							rounded>
							<div
								class="d-flex flex-row sliderBitch"
								:style="`background-position: ${sliderPos}; background-size: ${sliderSize}`">
								<div
									class="px-2 py-1"
									style="border-radius: 5px">
									<v-icon
										class="noHover"
										:color="`${
											chartType == 'chart-line'
												? 'kajot-anti-text'
												: 'kajot-text'
										}`"
										@click="chartType = 'chart-line'">
										mdi-chart-line
									</v-icon>
								</div>
								<div
									class="px-2 py-1"
									style="border-radius: 5px">
									<v-icon
										class="noHover"
										:color="`${
											chartType == 'chart-bar'
												? 'kajot-anti-text'
												: 'kajot-text'
										}`"
										@click="chartType = 'chart-bar'">
										mdi-chart-bar
									</v-icon>
								</div>
							</div>
						</v-sheet>
					</template>
				</v-card-title>
				<v-fade-transition
					appear
					leave-absolute>
					<div
						v-if="loading"
						style="height: 100%; width: 100%"
						class="pa-3">
						<div
							style="height: 100%; width: 100%"
							class="loader"
							:class="$vuetify.theme.dark ? 'dark' : 'light'"></div>
					</div>
				</v-fade-transition>
				<div
					v-if="!loading"
					class="d-flex flex-row align-center"
					style="height: 100%">
					<v-col
						:cols="showLegend ? 8 : 12"
						style="height: 100%">
						<Component
							eager
							:data="graphData"
							:options="options"
							:plugins="chartPlugins"
							ref="chart"
							:is="chartType"></Component>
					</v-col>
					<v-col
						class="pa-0"
						v-if="showLegend"
						cols="4">
						<div class="d-flex flex-column">
							<span
								style="user-select: none"
								@mouseenter="triggerHover(i)"
								@mouseleave="triggerHover(-1)"
								class="mb-1"
								v-for="(label, i) of def.labels[dataIndex]"
								:key="i">
								<v-icon
									:style="`color: ${getColor(i)} !important`"
									class="mr-2">
									{{ label.icon }}
								</v-icon>
								<span>{{ label.name }}</span>
							</span>
						</div>
					</v-col>
				</div>
			</div>
		</v-card>
	</DashboardWidget>
</template>

<script>
import DashboardWidget from "./DashboardWidget.vue";
import {
	Bar as chartBar,
	Line as chartLine,
	Doughnut as chartPie,
} from "vue-chartjs";
import { Chart as ChartJS } from "chart.js";
import * as chartutils from "../../constants/chartutils";
import * as fns from "date-fns";

function colorizeFn(opaque) {
	return (ctx) => {
		const v = ctx.parsed.y;
		const c =
			v > 0 ? chartutils.CHART_COLORS.blue : chartutils.CHART_COLORS.red;

		return opaque
			? chartutils.transparentize(c, 1)
			: chartutils.transparentize(c, 0.5);
	};
}

function colorize(val, opaque) {
	const c =
		val >= 0 ? chartutils.CHART_COLORS.blue : chartutils.CHART_COLORS.red;

	return opaque
		? chartutils.transparentize(c, 0.7)
		: chartutils.transparentize(c, 0.3);
}

export default {
	// components: { DashboardWidget },
	components: { DashboardWidget, chartBar, chartLine, chartPie },
	props: {
		def: {
			type: Object,
			default: () => ({}),
		},
		enabled: {
			type: Boolean,
			default: true,
		},
		showLegend: {
			type: Boolean,
			default: false,
		},
		showTypeSwitch: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: true,
		},
		timeframe: {
			type: String,
			default: "weekly",
		},
		currentTimeFrameIndex: {
			type: String,
		},
		selectedTime: {
			type: Number,
		},
		list: {
			type: Object,
		},
		dataKeys: {
			type: Array,
		},
	},
	data() {
		return {
			chartType: this.def.type,
			timeframes: ["yearly", "quarterly", "monthly", "weekly", "daily"],
			pieChart: undefined,
			w: 20,
			h: 19,
			dataIndex: 0,
		};
	},
	methods: {
		getColor(index) {
			try {
				return this.pieChart?.data?.datasets?.[this.dataIndex]
					?.backgroundColor?.[index];
			} catch (error) {
				console.log(error);
			}
		},
		triggerHover(i) {
			const ds = this.dataIndex;
			const tooltip = this.pieChart.tooltip;
			if (i === -1) {
				this.pieChart.setActiveElements([]);
				tooltip.setActiveElements([]);
			} else {
				this.pieChart.setActiveElements([
					{
						datasetIndex: 0,
						index: i,
					},
				]);
				tooltip.setActiveElements([
					{
						datasetIndex: 0,
						index: i,
					},
				]);
			}
			this.pieChart.update();
		},
		onResize(evt) {
			this.w = evt[`gs-w`];
			this.h = evt[`gs-h`];
		},
	},

	mounted() {
		if (!this.loading) {
			this.pieChart = ChartJS.getChart(this.$refs.chart.$el);
		}
	},

	watch: {
		loading(newVal) {
			setTimeout(() => {
				if (newVal === false)
					this.pieChart = ChartJS.getChart(this.$refs.chart.$el);
			}, 50);
		},
	},
	computed: {
		sliderPos() {
			return `${(this.chartType === "chart-bar") * 100}%`;
		},
		sliderSize() {
			return `${100 / 2}%`;
		},
		getXColor() {
			if (!this.graphData)
				return chartutils.transparentize(chartutils.CHART_COLORS.green, 1);
			const res = this.graphData?.labels.map(() => "#606060");

			if (this.selectedTime !== 0) {
				return res;
			}

			const currentIndex = this.graphData.datasets[0].data.findIndex(
				(el) => el.x === fns.format(new Date(), this.timeFrameFormat)
			);

			res[currentIndex] = chartutils.transparentize(
				chartutils.CHART_COLORS.green,
				1
			);
			return res;
		},
		getTimeframe() {
			switch (this.timeframe) {
				case "monthly":
					return fns.getDate(new Date());
				case "weekly":
					return (fns.getDay(new Date()) + 6) % 7;
				case "daily":
					return fns.getHours(new Date());
				default:
					return fns.getHours(new Date());
			}
		},
		timeFrameFormat() {
			switch (this.timeframe) {
				case "monthly": {
					return "dd";
				}
				case "weekly": {
					return "iiii";
				}
				case "daily": {
					return "HH";
				}
				case "quarterly": {
					return "ww";
				}
				case "yearly": {
					return "MM";
				}
				default: {
					return "yyy-MM-dd";
				}
			}
		},

		timeFrameFilter() {
			const x = this.timeFrameString;
			const parsedDate = fns.parse(
				this.currentTimeFrameIndex,
				"yyyy-MM-dd-HH",
				new Date()
			);
			const idx = this.timeframes.indexOf(this.timeframe);
			return (key) => {
				let res = true;
				if (idx >= this.timeframes.indexOf("monthly")) {
					res =
						res && this.list?.[x]?.[key]?.month === fns.getMonth(parsedDate);
				}

				if (idx >= this.timeframes.indexOf("weekly")) {
					res = this.list?.[x]?.[key]?.week === fns.getWeek(parsedDate);
				}

				if (idx >= this.timeframes.indexOf("daily")) {
					res = res && this.list?.[x]?.[key]?.day === fns.getDate(parsedDate);
				}
				return res;
			};
		},

		graphData() {
			if (this.chartType === "chart-pie" && !this.switcherTitle)
				return this.data[0];
			if (!this.switcherTitle) return this.data;

			return this.data[this.dataIndex];
		},
		chartPlugins() {
			return [
				{
					beforeDatasetDraw: (chart, args, options) => {
						if (this.selectedTime != 0) {
							return;
						}
						const {
							ctx,
							chartArea: { top, bottom, left, right, width, height },
							scales: { x, y },
						} = chart;

						const currentIndex = this.graphData.datasets[0].data.findIndex(
							(el) => el.x === fns.format(new Date(), this.timeFrameFormat)
						);

						const prevX = x.getPixelForValue(
							this.graphData.datasets[0].data[currentIndex - 1]?.x
						);
						const nowX = x.getPixelForValue(
							this.graphData.datasets[0].data[currentIndex]?.x
						);
						const nextX = x.getPixelForValue(
							this.graphData.datasets[0].data[currentIndex + 1]?.x,
							this.getTimeframe
						);

						const colWidth = nextX ? nextX - nowX : prevX - nowX;
						ctx.fillStyle = chartutils.transparentize(
							chartutils.CHART_COLORS.green,
							0.1
						);
						ctx.strokeStyle = chartutils.transparentize(
							chartutils.CHART_COLORS.green,
							0.6
						);

						ctx.beginPath();
						ctx.roundRect(nowX - colWidth / 2, top, colWidth, height, 5);
						ctx.fill();
					},
				},
			];
		},
		barOptions() {
			const format = this.timeFrameFormat;
			return {
				interaction: {
					intersect: false,
					mode: "index",
				},
				animation: {
					delay: (context) => {
						let delay = 0;
						if (context.type === "data" && context.mode === "default") {
							delay =
								context.dataIndex % 2 === 0
									? context.dataIndex * 20
									: context.dataIndex * 2;
						}
						return delay;
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						ticks: {
							display: true,
							color: this.getXColor,
						},
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
					},
					y: {
						ticks: {
							display: false,
						},
						border: {
							display: false,
						},
						grid: {
							display: false,
						},
					},
				},

				elements: {
					point: {
						pointStyle: "circle",
						borderColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan,
							1
						),
					},
					bar: {
						backgroundColor: colorizeFn(false),
						borderColor: colorizeFn(true),
						borderWidth: 2,
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					title: {
						font: {
							weigth: 500,
							size: 18,
							family: "'Roboto', sans-serif",
						},
						display: false,
						align: "start",
						color: "white",
						text: "Chart.js Bar Chart",
					},
					tooltip: {
						callbacks: {
							label: (context) => {
								const currentValue = this.$options.filters.Number(
									context.raw.y,
									{
										currency: "EUR",
									}
								);

								return ` ${currentValue}`;
							},
						},
					},
				},
			};
		},
		lineOptions() {
			return {
				interaction: {
					intersect: false,
					mode: "index",
				},
				animation: {
					delay: (context) => {
						let delay = 0;
						if (context.type === "data" && context.mode === "default") {
							delay = context.dataIndex * 30;
						}
						return delay;
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						ticks: {
							display: true,
							color: this.getXColor,
						},
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
					},
					y: {
						ticks: {
							display: false,
						},
						border: {
							display: false,
						},
						grid: {
							display: false,
						},
					},
				},

				elements: {
					point: {
						pointStyle: "circle",
						borderColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan,
							1
						),
					},
					line: {
						tension: 0.4,
						borderWidth: 2,
						borderColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan,
							1
						),
						backgroundColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan,
							1
						),
						fill: {
							target: "origin",
							above: chartutils.transparentize(
								chartutils.CHART_COLORS.logan,
								0.2
							), // Area will be red above the origin
							below: chartutils.transparentize(
								chartutils.CHART_COLORS.red,
								0.5
							), // And blue below the origin
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					title: {
						font: {
							weigth: 500,
							size: 18,
							family: "'Roboto', sans-serif",
						},
						display: false,
						align: "start",
						color: "white",
						text: "Chart.js Bar Chart",
					},
					tooltip: {
						callbacks: {
							label: (context) => {
								const currentValue = this.$options.filters.Number(
									context.raw.y,
									{
										currency: "EUR",
									}
								);

								return ` ${currentValue}`;
							},
						},
					},
				},
			};
		},
		pieOptions() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						ticks: {
							display: false,
						},
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
					},
					y: {
						ticks: {
							display: false,
						},
						border: {
							display: false,
						},
						grid: {
							display: false,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					title: {
						font: {
							weigth: 500,
							size: 18,
							family: "'Roboto', sans-serif",
						},
						display: false,
						align: "start",
						color: "white",
						text: "Chart.js Bar Chart",
					},
					colors: {
						forceOverride: true,
					},
					tooltip: {
						callbacks: {
							label: function (context) {
								var data = context.dataset.data,
									currentValue = context.raw,
									total = 0;

								for (var i = 0; i < data.length; i++) {
									total += data[i];
								}
								var percentage = parseFloat(
									((currentValue / total) * 100).toFixed(1)
								);

								return `${percentage}% (${currentValue})`;
							},
						},
					},
				},
			};
		},

		barData() {
			const x = this.timeFrameString;
			const data = Object.keys(this.list?.[x])
				.filter(this.timeFrameFilter)
				.map((el) => this.list?.[x]?.[el]);
			if (data.length === 0) {
				return {
					labels: [],
					datasets: [],
				};
			}
			const currentIndex = data.findIndex(
				(el) =>
					fns.format(el.date, this.timeFrameFormat) ===
					fns.format(new Date(), this.timeFrameFormat)
			);
			return {
				labels: data.map((el) => fns.format(el.date, this.timeFrameFormat)),
				datasets: [
					{
						data: data.map((el) => {
							return {
								y: el[this.def.dataKeys[0][0]],
								x: fns.format(el.date, this.timeFrameFormat),
							};
						}),
						radius: data.map((el, i) => (i === currentIndex ? 5 : 3)),
						backgroundColor: data.map((el, i) =>
							this.selectedTime == 0 && i === currentIndex
								? chartutils.transparentize(chartutils.CHART_COLORS.green, 0.3)
								: colorize(el[this.def.dataKeys[0][0]], false)
						),
						borderColor: data.map((el, i) =>
							this.selectedTime == 0 && i === currentIndex
								? chartutils.transparentize(chartutils.CHART_COLORS.green, 0.7)
								: colorize(el[this.def.dataKeys[0][0]], true)
						),
						minBarLength: 5,
						borderRadius: Number.MAX_VALUE,
						borderSkipped: false,
					},
				],
			};
		},

		lineData() {
			const x = this.timeFrameString;
			const data = Object.keys(this.list?.[x])
				.filter(this.timeFrameFilter)
				.map((el) => this.list?.[x]?.[el]);
			if (data.length === 0) {
				return {
					labels: [],
					datasets: [],
				};
			}
			const currentIndex = data.findIndex(
				(el) =>
					fns.format(el.date, this.timeFrameFormat) ===
					fns.format(new Date(), this.timeFrameFormat)
			);
			return {
				labels: data.map((el) => fns.format(el.date, this.timeFrameFormat)),
				datasets: [
					{
						data: data.map((el) => {
							return {
								y: el[this.def.dataKeys[0][0]],
								x: fns.format(el.date, this.timeFrameFormat),
							};
						}),
						radius: data.map((el, i) =>
							this.selectedTime == 0 && i === currentIndex ? 5 : 3
						),
						borderWidth: data.map((el, i) =>
							this.selectedTime == 0 && i === currentIndex ? 3 : 2
						),
						pointBackgroundColor: data.map((el, i) =>
							this.selectedTime == 0 && i === currentIndex
								? chartutils.transparentize(chartutils.CHART_COLORS.green, 0.4)
								: colorize(el[this.def.dataKeys[0][0]], false)
						),
						pointBorderColor: data.map((el, i) =>
							this.selectedTime == 0 && i === currentIndex
								? chartutils.transparentize(chartutils.CHART_COLORS.green, 0.7)
								: chartutils.transparentize(chartutils.CHART_COLORS.logan, 0.7)
						),
						hoverBorderWidth: data.map((el, i) =>
							this.selectedTime == 0 && i === currentIndex ? 4 : 3
						),
						hoverRadius: data.map((el, i) =>
							this.selectedTime == 0 && i === currentIndex ? 7 : 4
						),
						borderRadius: Number.MAX_VALUE,
						borderSkipped: false,
					},
				],
			};
		},

		pieData() {
			if (this.chartType !== "chart-pie") return [];
			return this.def.labels.map((label, i) => {
				const labels = label.map((el) => el.name);
				return {
					labels: labels,
					datasets: [
						{
							borderColor: this.$vuetify.theme.dark ? "#1e1e1eff" : "#ffffffff",
							borderWidth: "4",
							data: labels.map(
								(el) =>
									this.list?.[`${this.timeframe}Global`]?.[
										`${this.currentTimeFrameIndex}`
									][el.toLowerCase()] ?? 0
							),
						},
					],
				};
			});
		},
		switcherTitle() {
			return Array.isArray(this.def.title) && this.def.title.length === 2;
		},
		timeFrameString() {
			switch (this.timeframe) {
				case "daily":
					return "hourly";
				case "weekly":
				case "monthly":
					return `dailyGlobal`;
				case "quarterly":
					return `weeklyGlobal`;
				case "yearly":
					return `monthlyGlobal`;
				default:
					return `dailyGlobal`;
			}
		},
		options() {
			const options = this[`${this.chartType.split("-")[1]}Options`];
			if (this.w >= 12) {
				return options;
			}

			return {
				...options,
				scales: {
					...options.scales,
					x: {
						...options.scales.x,
						ticks: {
							display: false,
						},
					},
				},
			};
		},
		data() {
			const data = this[`${this.chartType.split("-")[1]}Data`];
			return data;
		},
		widget() {
			return {
				layout: {
					minW: 10,
					minH: 10,
					w: this.def.w ?? 20,
					h: this.def.h ?? 22,
					x: this.def.x ?? undefined,
					y: this.def.y ?? undefined,
				},
			};
		},
	},
};
</script>

<style scoped lang="sass">
.sliderBitch
	position: relative
	border-radius: 5px
	background-image: linear-gradient(var(--v-primary-base),var(--v-primary-base))
	transition: background-position 0.35s cubic-bezier(0.47, 1.64, 0.41, 0.8)

.noHover::after
	opacity: 0 !important
	background-color: transparent !important

.loader
	&.dark
		background: var(--v-secondary-base)
	&.light
		background: var(--v-secondary-lighten1)
	border-radius: 5px
	overflow: hidden
	@keyframes loading-lol
		100%
			transform: translateX(100%)
	&::before
		content: ''
		position: relative
		display: block
		width: 100%
		height: 100%
		animation: loading-lol 1.5s linear infinite
		transform: translateX(-100%)
	&.dark::before
		background: linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.05),hsla(0,0%,100%,0))
	&.light::before
		background: linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.7),hsla(0,0%,100%,0))
</style>
