var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._b(
      {
        ref: "gridS",
        staticClass: "grid-stack-item",
        staticStyle: { overflow: "visible" },
      },
      "div",
      _vm.gridStackAttributes,
      false
    ),
    [
      _c(
        "div",
        {
          staticClass: "grid-stack-item-content",
          staticStyle: { overflow: "visible" },
        },
        [
          _vm._t("default"),
          _vm.enabled
            ? _c(
                "div",
                {
                  staticClass: "grid-stack-handle pa-3",
                  staticStyle: {
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  },
                },
                [
                  _c(
                    "v-icon",
                    { staticStyle: { cursor: "grab" }, attrs: { size: "30" } },
                    [_vm._v(" mdi-drag ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$attrs.cls === "pca"
            ? _c(
                "v-tooltip",
                {
                  attrs: { color: "warning", top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "-10px",
                                      right: "-10px",
                                    },
                                    attrs: {
                                      fab: "",
                                      height: "20",
                                      width: "20",
                                      color: "warning",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { size: "15" } }, [
                                  _vm._v("mdi-help"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    358225903
                  ),
                },
                [_c("span", [_vm._v("Warning")])]
              )
            : _vm._e(),
          _vm.$attrs.cls === "kkd"
            ? _c(
                "v-tooltip",
                {
                  attrs: { color: "error", top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "-10px",
                                      right: "-10px",
                                    },
                                    attrs: {
                                      fab: "",
                                      height: "20",
                                      width: "20",
                                      color: "error",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { size: "15" } }, [
                                  _vm._v("mdi-exclamation"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    22609071
                  ),
                },
                [_c("span", [_vm._v("Error")])]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }