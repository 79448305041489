<template>
	<DashboardWidget
		:widget="widget"
		:enabled="enabled"
		:cls="def.cls">
		<v-card
			flat
			:class="def.cls"
			class="my-0 mx-0 d-flex align-center"
			style="height: 100%">
			<div class="d-flex align-center pl-3">
				<v-col class="d-flex justify-center px-0">
					<v-fade-transition
						appear
						group
						leave-absolute>
						<v-progress-circular
							v-if="iLoading"
							key="loading"
							:indeterminate="iLoading"
							ref="transformer"
							:color="
								iLoading2
									? 'primary'
									: (def.value / def.compareVal) * 100 - 100 > 0
									? 'success'
									: 'error'
							"
							:value="
								iLoading2
									? 0
									: Math.abs((def.value / def.compareVal) * 100 - 100)
							"></v-progress-circular>
						<v-progress-circular
							v-else
							key="data"
							:indeterminate="iLoading"
							ref="transformer"
							:color="
								iLoading2
									? 'primary'
									: def.value / def.compareVal > 1
									? 'success'
									: 'error'
							"
							:value="
								iLoading2 ? 0 : (def.value / def.compareVal) * 100
							"></v-progress-circular>
					</v-fade-transition>
				</v-col>
				<v-col
					cols="9"
					class="mx-2 align-center px-0">
					<div class="d-block text-truncate">{{ def.title }}</div>

					<v-slide-y-reverse-transition
						group
						leave-absolute>
						<div
							v-if="!iLoading"
							class="d-flex align-center"
							key="real">
							<span class="text-h5">
								{{ def.prefix }}
								{{ def.filter ? def.filter(def.value) : def.value
								}}{{ def.postfix }}
							</span>
							<span
								v-if="def.compareVal > 0 && def.value > 0"
								class="ml-2 text-caption d-flex align-center"
								:class="`${
									def.value / def.compareVal > 1 ? 'success' : 'error'
								}--text`">
								{{ (def.value / def.compareVal) | Percentage }}
							</span>
							<!-- Math.round((def.value / def.compareVal) * 100 - 100) -->
							<!-- (def.value / def.compareVal) | Percentage -->
						</div>
						<div
							v-else
							class="d-flex align-center"
							key="fake">
							<span class="text-h5">&nbsp;</span>
						</div>
					</v-slide-y-reverse-transition>
				</v-col>
			</div>
		</v-card>
	</DashboardWidget>
</template>

<script>
import get from "lodash/get";
import DashboardWidget from "./DashboardWidget.vue";
export default {
	components: { DashboardWidget },
	props: {
		def: {
			type: Object,
			default: () => ({}),
		},
		enabled: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			iLoading: true,
			iLoading2: true,
		};
	},
	methods: {
		async showData() {
			const stopTime =
				1800 -
				(this.$refs.transformer.$el.children[0].children[0].getAnimations()[0]
					.currentTime %
					1800);

			setTimeout(() => {
				this.iLoading = false;
				setTimeout(() => (this.iLoading2 = false), 50);
				this.$emit("elementLoading", false);
			}, Math.max(stopTime - 200, 0));
		},
	},
	created() {
		if (!this.loading) {
			this.iLoading = false;
			this.iLoading2 = false;
			this.$emit("elementLoading", false);
		}
	},
	computed: {
		widget() {
			return {
				id: this.def.id,
				layout: {
					w: this.def.w ?? 12,
					h: this.def.h ?? 5,
					x: this.def.x ?? undefined,
					y: this.def.y ?? undefined,
					minW: this.def.minW ?? undefined,
					minH: this.def.minH ?? undefined,
				},
			};
		},
	},
	watch: {
		loading(newVal) {
			if (newVal === false) {
				this.showData();
			} else {
				this.iLoading = true;
				this.iLoading2 = true;
			}
		},
	},
};
</script>
