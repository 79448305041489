<template>
	<DashboardWidget
		:widget="widget"
		:enabled="enabled"
		:cls="def.cls">
		<v-card
			flat
			class="my-0 mx-0"
			:class="def.cls"
			style="height: 100%">
			<v-card-title>{{ def.title }}</v-card-title>
			<chartLine
				v-if="def.type == 'chart'"
				style="
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
				"
				:data="def.data"
				:options="def.options"></chartLine>
			<div class="mx-4 mt-7 mb-4 d-flex flex-row align-center">
				<v-progress-linear
					key="data"
					:indeterminate="iLoading"
					ref="transformer"
					rounded
					height="4"
					:color="
						iLoading2
							? 'primary'
							: def.classFn
							? def.classFn(def.value)
							: def.value / def.compareVal > 1
							? 'success'
							: 'error'
					"
					:value="
						iLoading2
							? 0
							: def.progressVal
							? def.progressVal(def.value, def.compareVal)
							: (def.value / def.compareVal) * 100
					"></v-progress-linear>
			</div>
			<v-slide-y-reverse-transition>
				<v-card-text
					v-if="!iLoading"
					class="py-0 d-flex align-center">
					<span class="text-h4">
						{{ def.filter ? def.filter(def.value) : def.value }}
					</span>
					<span
						v-if="def.compareVal > 0 && def.value !== 0"
						class="text-h7 ml-2"
						:class="
							def.value / def.compareVal > 1 ? 'success--text' : 'error--text'
						">
						{{
							def.differenceVal &&
							def.differenceVal(def.value, def.compareVal) > 0
								? "+"
								: ""
						}}
						{{
							def.differenceVal
								? def.differenceVal(def.value, def.compareVal)
								: (def.value / def.compareVal) | Percentage
						}}
					</span>
				</v-card-text>
			</v-slide-y-reverse-transition>
		</v-card>
	</DashboardWidget>
</template>

<script>
import get from "lodash/get";
import DashboardWidget from "./DashboardWidget.vue";
import { Line as chartLine } from "vue-chartjs";
export default {
	components: { DashboardWidget, chartLine },
	data() {
		return {
			iLoading: true,
			iLoading2: true,
		};
	},
	props: {
		def: {
			type: Object,
			default: () => ({}),
		},
		enabled: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		async showData() {
			const stopTime =
				1800 -
				(this.$refs.transformer.$el.children[2].children[0].getAnimations()[0]
					.currentTime %
					1800);

			setTimeout(() => {
				this.iLoading = false;
				setTimeout(() => (this.iLoading2 = false), 50);
				this.$emit("elementLoading", false);
			}, Math.max(stopTime, 0));
		},
	},
	computed: {
		widget() {
			return {
				layout: {
					w: this.def.w ?? this.$attrs.w ?? 15,
					h: this.def.h ?? this.$attrs.h ?? 8,
					x: this.def.x ?? this.$attrs.x ?? undefined,
					y: this.def.y ?? this.$attrs.y ?? undefined,
				},
			};
		},
	},
	created() {
		if (!this.loading) {
			this.iLoading = false;
			this.iLoading2 = false;
			this.$emit("elementLoading", false);
		}
	},
	watch: {
		loading(newVal) {
			if (newVal === false) {
				this.showData();
			} else {
				this.iLoading = true;
				this.iLoading2 = true;
				this.$emit("elementLoading", true);
			}
		},
	},
};
</script>
