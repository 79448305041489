<template>
	<div
		class="mrditko"
		style="min-height: 90vh">
		<!-- <v-btn
			v-if="!TODO"
			:style="
				overTrash
					? 'background-color: rgba(255, 0, 0, 0.8)'
					: 'background-color: rgba(255, 0, 0, 0.4)'
			"
			class="trash odmrdavac"
			:class="{ active: overTrash }"
			fab
			><v-icon large>mdi-trash-can</v-icon></v-btn
		> -->
		<!-- <div
			v-if="overlay"
			:style="`background-color: ${rgbaFromTheme('background', 20)}`"
			style="
				position: absolute;
				width: 100vw;
				height: 100%;
				backdrop-filter: blur(8px);
				z-index: 2;
			"
			><v-btn
				@click="despawnOverlay()"
				style="position: absolute; z-index: 2000"
				small
				fab
				text
				><v-icon>mdi-close</v-icon></v-btn
			></div
		> -->
		<!-- <div
			style="position: absolute; z-index: 9999; width: 95vw; overflow: visible"
			class="grid-stack2"
			:key="counter"
			:style="!overlay ? 'visibility: hidden' : ''">
			<SmallEl
				class="namrdavac"
				v-for="def in [smallsDef[0]]"
				:def="def"
				:enabled="!gridStop"
				:key="`${def.id}`" />
		</div> -->

		<!-- <v-btn v-if="!TODO" @click="spawnOverlay"
			><v-icon>mdi-widgets</v-icon></v-btn
		> -->

		<v-sheet
			color="transparent"
			class="d-flex align-center mx-4">
			<v-tooltip top>
				<template #activator="{ on, attrs }">
					<v-chip
						:disabled="selectedTime !== 0"
						v-on="on"
						v-bind="attrs"
						color="secondary">
						LIVE
						<div
							v-if="selectedTime === -1"
							class="ml-3 error--text red-glow"></div>
						<div
							v-else
							class="ml-3 success--text green-glow"></div>
					</v-chip>
				</template>
				<span>
					Last Update: {{ relTime == "now" ? relTime : `${relTime} ago` }}
				</span>
			</v-tooltip>
			<v-spacer></v-spacer>
			<v-chip-group
				class="mt-1"
				mandatory
				v-model="selectedTime">
				<v-chip
					:small="isMobile"
					active-class="primary"
					class="k-reset mx-1 transitionall"
					:value="-1">
					Last {{ timeframeName }}
				</v-chip>
				<v-chip
					:small="isMobile"
					active-class="primary"
					class="k-reset mx-1 transitionall"
					:value="0">
					This {{ timeframeName }}
				</v-chip>
			</v-chip-group>
			<v-divider
				:class="`${!isMobile ? `mx-7 my-3` : `mx-2 my-2`}`"
				vertical></v-divider>
			<v-chip-group
				class="mt-1"
				mandatory
				v-model="timeframe">
				<v-chip
					:small="isMobile"
					v-for="t in timeframes"
					:key="t[0]"
					active-class="primary"
					class="k-reset mx-1 transitionall"
					:value="t[0]">
					{{ t[1] }}
				</v-chip>
			</v-chip-group>
			<v-divider
				key="compare-divider"
				:class="`${!isMobile ? `mx-7 my-3` : `mx-2 my-2`}`"
				vertical></v-divider>
			<v-chip-group
				key="compare-chips"
				class="mt-1"
				mandatory
				v-model="compareMode">
				<v-chip
					:small="isMobile"
					:disabled="selectedTime != 0"
					v-for="c in compareModes"
					:key="c"
					active-class="primary"
					class="k-reset mx-1 transitionall"
					:value="c">
					{{ c }}
				</v-chip>
			</v-chip-group>
			<v-spacer v-if="$attrs.fs"></v-spacer>
		</v-sheet>

		<div class="grid-stack mb-6 mx-2">
			<SmallEl
				:loading="loading"
				v-for="def in smallsDef"
				:def="def"
				:enabled="!gridStop"
				:key="def.id" />
			<MidEl
				:loading="loading"
				@elementLoading="elementLoading"
				v-for="def in filteredMidDefs"
				:w="60 / filteredMidDefs.length"
				:def="def"
				:enabled="!gridStop"
				:key="def.id" />
			<GraphEl
				v-for="def of filteredGraphDefs"
				:loading="uiLoading"
				:showTypeSwitch="def.type != 'chart-pie'"
				:showLegend="def.type == 'chart-pie'"
				:def="def"
				:list="list"
				:timeframe="timeframe"
				:selectedTime="selectedTime"
				:currentTimeFrameIndex="currentTimeFrameIndex"
				:enabled="!gridStop"
				:key="def.id"></GraphEl>
			<TableEl
				:iLoading="topsLoading"
				v-for="def in filteredTableDefs"
				:def="def"
				:enabled="!gridStop"
				:key="def.id"></TableEl>
			<!-- <SmallEl
				v-for="def in additions"
				:def="def"
				:id="def.id"
				:enabled="!gridStop"
				:key="def.id" /> -->
		</div>
	</div>
</template>

<script>
import SmallEl from "../components/dashboard/SmallEl.vue";
import MidEl from "../components/dashboard/MidEl.vue";
import GraphEl from "../components/dashboard/GraphEl.vue";
import TableEl from "../components/dashboard/TableEl.vue";
import * as chartutils from "../constants/chartutils";
import { rgbaFromTheme, isMobile } from "../constants/helpers";
import permissions from "../mixins/permissions";
import localstorage from "../mixins/localstorage";
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as fns from "date-fns";
import _ from "lodash";

export default {
	name: "App",
	mixins: [permissions, localstorage],
	components: {
		SmallEl,
		MidEl,
		GraphEl,
		TableEl,
	},
	async created() {
		this.$syncAndAssign(
			this.$route.name,
			"compareMode",
			"selectedTime",
			"timeframe"
		);
		if (!this.updated || Date.now() > this.updated - 0 + 5 * 60 * 1000) {
			this.uiLoading = true;
			await this.loadData(true);
		}
		this.showLoading = false;
		this.setUpdateInterval();
	},
	destroyed() {
		clearInterval(this.updateInterval);
	},
	data() {
		return {
			uiLoading: false,
			relTime: "now",
			partialVisible: true,
			showLoading: true,

			updateInterval: undefined,

			winSessions: [],
			betSessions: [],
			TODO: "Finish dashboard",

			timeframes: [
				// ["yearly", "Year"],
				// ["quarterly", "Quarter"],
				["monthly", "Month"],
				["weekly", "Week"],
				["daily", "Day"],
			],
			compareModes: ["Global", "Partial"],

			dSelectedTime: 0,
			dTimeframe: "weekly", //Selected Timeframe
			compareMode: "Global", //Selected CompareMode

			// lastAdded: "",
			// additions: [],
			// overTrash: false,
			// counter: 1,
			// counter2: 1,
			// overlay: false,

			grid: null,
			gridStop: true,

			pieLabels: [
				[
					{ name: "Desktop", icon: "mdi-monitor" },
					{ name: "Tablet", icon: "mdi-tablet" },
					{ name: "Mobile", icon: "mdi-cellphone" },
				],
				[
					{ name: "Portrait", icon: "mdi-cellphone" },
					{ name: "Landscape", icon: "mdi-tablet" },
				],
			],
		};
	},
	methods: {
		...mapActions("dashboard", {
			loadList: "loadList",
			loadTops: "loadTops",
		}),
		...mapActions("sessions2", {
			loadSessions: "loadList",
		}),
		...mapMutations("sessions2", {
			pagination: "pagination",
		}),
		...mapMutations("globalTicker", {
			sub: "subscribe",
			unsub: "unsubscribe",
		}),
		relTimeUpdater() {
			this.relTime = this.relativeTime(this.updated);
		},
		setUpdateInterval() {
			if (this.updateInterval) {
				clearInterval(this.updateInterval);
			}
			this.updateInterval = setInterval(() => {
				if (this.selectedTime !== 0) {
					return;
				}
				this.loadData();
			}, 5 * 60 * 1000);
		},
		relativeTime(date) {
			if (!date) return;
			return fns.formatDistanceToNow(date);
		},
		playerClick(item, _, e) {
			if (
				!this._usrFlagsSome({
					key: "player.detail",
					val: this.permissions.READ,
				})
			)
				return;

			const breadcrumbs = {
				title: `Player ${item.player_mongo_id}`,
				link: "/players/{player_id}",
				name: "Player detail",
				hard: true,
			};
			if (e.ctrlKey === true || e.metaKey === true) {
				const tempRoute = this.$router.resolve({
					name: "player-detail",
					params: {
						player_id: item.player_mongo_id,
						breadcrumbs,
					},
				});
				window.open(tempRoute.href);
			} else {
				this.$router.push({
					name: "player-detail",
					params: {
						player_id: item.player_mongo_id,
						breadcrumbs,
					},
				});
			}
		},
		sessionClick(item, _, e) {
			if (
				!this._usrFlagsSome({
					key: "session.detail",
					val: this.permissions.READ,
				})
			)
				return;

			const breadcrumbs = {
				title: `Session ${item.session_mongo_id}`,
				link: "/sessions/{session_id}",
				name: "Session detail",
				hard: true,
			};
			if (e.ctrlKey === true || e.metaKey === true) {
				const tempRoute = this.$router.resolve({
					name: "session-detail",
					params: {
						session_id: item.session_mongo_id,
						breadcrumbs,
					},
				});
				window.open(tempRoute.href);
			} else {
				this.$router.push({
					name: "session-detail",
					params: {
						session_id: item.session_mongo_id,
						breadcrumbs,
					},
				});
			}
		},
		async loadData(showLoading = false) {
			await this.loadList({
				payload: {
					date_from: fns.startOfMonth(fns.addMonths(new Date(), -2)),
					date_to: fns.endOfMonth(new Date()),
				},
				showLoading,
			});
			await this.loadTimestampedTops();
		},
		async loadTimestampedTops() {
			const minDate = fns.parse(
				this.indexer.current[this.timeframe],
				"yyyy-MM-dd-HH",
				new Date()
			);
			const maxDate = fns.parse(
				this.indexer.currentEnd[this.timeframe],
				"yyyy-MM-dd-HH",
				new Date()
			);

			await this.loadTops({
				date_from: minDate,
				date_to: maxDate,
			});
		},
		elementLoading(e) {
			this.uiLoading = e;
		},
		rgbaFromTheme,
		// async despawnOverlay() {
		// 	this.overlay = false;
		// 	this.counter++;
		// 	await this.$nextTick();
		// 	this.grid2.destroy();
		// },
		// async spawnOverlay() {
		// 	this.grid2 = window.GridStack.init(
		// 		{
		// 			cellHeight: 20,
		// 			column: 60,
		// 			margin: 7,
		// 			disableResize: true,
		// 			float: true,
		// 			handle: ".grid-stack-handle",
		// 			dragOut: false,
		// 		},
		// 		".grid-stack2"
		// 	);

		// 	this.grid2.compact("compact");
		// 	this.grid2.on("dragstart", async (a, b, c, d) => {
		// 		b.style.visibility = "visible";
		// 		this.overlay = false;
		// 	});
		// 	this.grid2.on("removed", async (a, b, c, d) => {
		// 		console.log(a);
		// 		this.despawnOverlay();
		// 	});
		// 	await this.$nextTick();
		// 	this.overlay = true;
		// },
		compactGrid() {
			this.grid.compact("list");
		},
	},
	computed: {
		isMobile,
		...mapGetters(["currentCasino"]),
		...mapGetters("dashboard", {
			list: "list",
			updated: "updated",
			loading: "listLoading",
			topsLoading: "getTopsLoading",
			topPlayers: "getTopPlayers",
			topSessions: "getTopSessions",
		}),
		...mapGetters("sessions2", {
			sessionList: "list",
		}),
		timeframeName() {
			return this.timeframes.find((el) => el[0] === this.timeframe)[1];
		},
		selectedTime: {
			get() {
				return this.dSelectedTime;
			},
			set(e) {
				if (this.dSelectedTime === e) return;
				this.dSelectedTime = e;
				this.loadTimestampedTops();
				if (e === -1) {
					this.compareMode = this.compareModes[0];
				}
				if (e === 0) {
					if (Date.now() > this.updated - 0 + 5 * 60 * 1000) {
						this.loadData();
						this.setUpdateInterval();
					}
				}
			},
		},
		timeframe: {
			get() {
				return this.dTimeframe;
			},
			set(e) {
				if (this.dTimeframe === e) return;
				this.dTimeframe = e;
				this.loadTimestampedTops();
			},
		},
		hasStats() {
			return this._usrFlagsSome({
				key: "statistic",
				val: this.permissions.READ,
			});
		},
		indexer() {
			return {
				current: {
					yearly: fns.format(
						fns.startOfYear(fns.addYears(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
					quarterly: fns.format(
						fns.startOfQuarter(fns.addQuarters(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
					monthly: fns.format(
						fns.startOfMonth(fns.addMonths(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
					weekly: fns.format(
						fns.startOfWeek(fns.addWeeks(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
					daily: fns.format(
						fns.startOfDay(fns.addDays(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
				},
				last: {
					yearly: fns.format(
						fns.startOfMonth(fns.addYears(new Date(), this.selectedTime - 1)),
						"yyyy-MM-dd-HH"
					),
					quarterly: fns.format(
						fns.startOfMonth(
							fns.addQuarters(new Date(), this.selectedTime - 1)
						),
						"yyyy-MM-dd-HH"
					),
					monthly: fns.format(
						fns.startOfMonth(fns.addMonths(new Date(), this.selectedTime - 1)),
						"yyyy-MM-dd-HH"
					),
					weekly: fns.format(
						fns.startOfWeek(fns.addWeeks(new Date(), this.selectedTime - 1)),
						"yyyy-MM-dd-HH"
					),
					daily: fns.format(
						fns.startOfDay(fns.addDays(new Date(), this.selectedTime - 1)),
						"yyyy-MM-dd-HH"
					),
				},
				currentEnd: {
					yearly: fns.format(
						fns.endOfYear(fns.addMonths(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
					quarterly: fns.format(
						fns.endOfQuarter(fns.addMonths(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
					monthly: fns.format(
						fns.endOfMonth(fns.addMonths(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
					weekly: fns.format(
						fns.endOfWeek(fns.addWeeks(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
					daily: fns.format(
						fns.endOfDay(fns.addDays(new Date(), this.selectedTime)),
						"yyyy-MM-dd-HH"
					),
				},
				lastEnd: {
					yearly: fns.format(
						fns.endOfYear(fns.addMonths(new Date(), this.selectedTime - 1)),
						"yyyy-MM-dd-HH"
					),
					quarterly: fns.format(
						fns.endOfQuarter(fns.addMonths(new Date(), this.selectedTime - 1)),
						"yyyy-MM-dd-HH"
					),
					monthly: fns.format(
						fns.endOfMonth(fns.addMonths(new Date(), this.selectedTime - 1)),
						"yyyy-MM-dd-HH"
					),
					weekly: fns.format(
						fns.endOfWeek(fns.addWeeks(new Date(), this.selectedTime - 1)),
						"yyyy-MM-dd-HH"
					),
					daily: fns.format(
						fns.endOfDay(fns.addDays(new Date(), this.selectedTime - 1)),
						"yyyy-MM-dd-HH"
					),
				},
			};
		},
		currentTimeFrameIndex() {
			return this.indexer.current[this.timeframe];
		},
		lastTimeFrameIndex() {
			return this.indexer.last[this.timeframe];
		},

		smallsDef() {
			return [
				{
					y: 0,
					x: 48,
					title: "Avg Bet",
					compareVal:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.lastTimeFrameIndex}`
						]?.avg_bet,
					value:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.currentTimeFrameIndex}`
						]?.avg_bet,
					filter: (e) => {
						return this.$options.filters.Number(e, { currency: "EUR" });
					},
				},
				{
					y: 0,
					x: 36,
					title: "Avg Win",
					compareVal:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.lastTimeFrameIndex}`
						]?.avg_win,
					value:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.currentTimeFrameIndex}`
						]?.avg_win,
					filter: (e) => {
						return this.$options.filters.Number(e, { currency: "EUR" });
					},
				},
				{
					y: 0,
					x: 24,
					title: "Rounds / Session",
					compareVal:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.lastTimeFrameIndex}`
						]?.avg_length,
					value:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.currentTimeFrameIndex}`
						]?.avg_length,
					filter: (e) => {
						return this.$options.filters.Number(e, {
							style: undefined,
							currency: undefined,
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						});
					},
				},
				{
					y: 0,
					x: 12,
					title: "New Players",
					compareVal:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.lastTimeFrameIndex}`
						]?.new_player,
					value:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.currentTimeFrameIndex}`
						]?.new_player,
					filter: (e) => {
						return this.$options.filters.Number(e, {
							style: undefined,
							currency: undefined,
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						});
					},
				},
				{
					y: 0,
					x: 0,
					title: "Free Spins",
					compareVal:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.lastTimeFrameIndex}`
						]?.bonus_round ?? 0,
					value:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.currentTimeFrameIndex}`
						]?.bonus_round ?? 0,
					filter: (e) => {
						return this.$options.filters.Number(e, {
							style: undefined,
							currency: undefined,
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						});
					},
				},
			];
		},
		chartMiniData() {
			return {
				labels: chartutils.months({ count: 12 }),

				datasets: [
					{
						pointStyle: false,
						data: chartutils.numbers({ count: 12, min: -15, max: 100 }),
						backgroundColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan,
							0.5
						),
						borderColor: chartutils.transparentize(
							chartutils.CHART_COLORS.logan
						),
						borderWidth: 2,
						borderRadius: Number.MAX_VALUE,
						borderSkipped: false,
					},
				],
			};
		},
		chartMiniOptions() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						ticks: {
							display: false,
						},
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
					},
					y: {
						ticks: {
							display: false,
						},
						border: {
							display: false,
						},
						grid: {
							display: false,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					title: {
						font: {
							weigth: 500,
							size: 18,
							family: "'Roboto', sans-serif",
						},
						display: false,
						align: "start",
						color: "white",
						text: "Chart.js Bar Chart",
					},
					colors: {
						forceOverride: true,
					},
				},
			};
		},
		midDefs() {
			return [
				// {
				// 	title: "Sessions",
				// 	compareVal: 100,
				// 	value: 25,
				// 	type: "chart",
				// 	data: this.chartMiniData,
				// 	options: this.chartMiniOptions,
				// },
				{
					y: 5,
					x: !this.hasStats ? 40 : 45,
					title: "RTP",
					progressVal: (val) => {
						return val * 100;
					},
					differenceVal: (val, cmpVal) => {
						return val - cmpVal;
					},
					classFn: (val) => {
						switch (true) {
							case (val > 0 && val <= 0.85) || val > 1.05:
								return "error";
							case (val > 0.85 && val <= 0.9) || (val > 1.0 && val <= 1.05):
								return "rtp-orange";
							default:
								return "success";
						}
					},
					compareVal:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.lastTimeFrameIndex}`
						]?.rtp,
					value:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.currentTimeFrameIndex}`
						]?.rtp,
					filter: (e) => {
						return this.$options.filters.Number(e, {
							style: "percent",
						});
					},
					type: "normal",
				},
				{
					y: 5,
					x: !this.hasStats ? 20 : 30,
					title: "Sessions",
					compareVal:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.lastTimeFrameIndex}`
						]?.session,
					value:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.currentTimeFrameIndex}`
						]?.session,
					type: "normal",
					filter: (e) => {
						return this.$options.filters.Number(e, {
							style: undefined,
							currency: undefined,
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						});
					},
				},
				{
					y: 5,
					x: !this.hasStats ? 0 : 15,
					title: "Rounds",
					// cls: "pca",
					compareVal:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.lastTimeFrameIndex}`
						]?.round,
					value:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.currentTimeFrameIndex}`
						]?.round,
					type: "normal",
					filter: (e) => {
						return this.$options.filters.Number(e, {
							style: undefined,
							currency: undefined,
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						});
					},
				},
				{
					y: 5,
					x: 0,
					hidden: () => !this.hasStats,
					title: "Bet",
					compareVal:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.lastTimeFrameIndex}`
						]?.bet_sum,
					value:
						this.list?.[`${this.timeframe}${this.compareMode}`]?.[
							`${this.currentTimeFrameIndex}`
						]?.bet_sum,
					filter: (e) => {
						return this.$options.filters.Number(e, { currency: "EUR" });
					},
					type: "normal",
				},
			];
		},
		filteredMidDefs() {
			return this.midDefs.filter((el) => !el.hidden?.());
		},
		graphDefs() {
			return [
				{
					y: 13,
					x: 0,
					w: 30,
					title: "Devices",
					hidden: () => this.hasStats,
					labels: [this.pieLabels[0]],
					dataKeys: [["desktop", "tablet", "mobile"]],
					type: "chart-pie",
				},
				{
					y: 13,
					x: 30,
					w: 30,
					title: "Orientations",
					hidden: () => this.hasStats,
					labels: [this.pieLabels[1]],
					dataKeys: [["portrait", "landscape"]],
					type: "chart-pie",
				},
				{
					y: 13,
					x: 40,
					hidden: () => !this.hasStats,
					title: ["Devices", "Orientations"],
					labels: this.pieLabels,
					dataKeys: [
						["desktop", "tablet", "mobile"],
						["portrait", "landscape"],
					],
					type: "chart-pie",
				},
				{
					y: 13,
					x: 20,
					hidden: () => !this.hasStats,
					title: "Netto",
					dataKeys: [["netto"]],
					type: "chart-line",
				},
				{
					y: 13,
					x: 0,
					hidden: () => !this.hasStats,
					title: "Bets",
					dataKeys: [["bet_sum"]],
					type: "chart-line",
				},
			];
		},
		filteredGraphDefs() {
			return this.graphDefs.filter((el) => !el.hidden?.());
		},

		tableDefs() {
			return [
				{
					y: 35,
					x: 30,
					hidden: () => !this.hasStats,
					headers: [
						{
							text: "No.",
							value: "no",
							align: "left",
							isData: false,
						},
						{
							text: "Player ID",
							value: "player_mongo_id",
							align: "left",
							isData: false,
							shorten: 15,
						},
						{
							text: "Σ Bet",
							value: "bet_sum",
							align: "right",
							isData: true,
							formatter: (el) => {
								return this.$options.filters.Number(el, { currency: "EUR" });
							},
						},
						{
							text: "Σ Win",
							value: "win_sum",
							align: "right",
							isData: true,
							formatter: (el) => {
								return this.$options.filters.Number(el, { currency: "EUR" });
							},
						},
						{
							text: "Rounds",
							value: "round",
							align: "right",
							isData: true,
							formatter: (el) => {
								return this.$options.filters.Number(el, {
									style: undefined,
									currency: undefined,
									minimumFractionDigits: 0,
									maximumFractionDigits: 2,
								});
							},
						},
						{
							text: "Sessions",
							value: "session",
							align: "right",
							isData: true,
							formatter: (el) => {
								return this.$options.filters.Number(el, {
									style: undefined,
									currency: undefined,
									minimumFractionDigits: 0,
									maximumFractionDigits: 2,
								});
							},
						},
					],
					items: this.topPlayers,
					sort_by: "win_sum",
					on: {
						"click:row": this.playerClick,
					},
					title: "Biggest Players",
				},
				{
					y: 35,
					x: 0,
					hidden: () => !this.hasStats,
					headers: [
						{
							text: "No.",
							value: "no",
							align: "left",
							isData: false,
						},
						{
							text: "Game name",
							value: "game_name",
							align: "left",
							isData: false,
						},
						{
							text: "Σ Bet",
							value: "bet_sum",
							align: "right",
							isData: true,
							formatter: (el) => {
								return this.$options.filters.Number(el, { currency: "EUR" });
							},
						},
						{
							text: "Σ Win",
							value: "win_sum",
							align: "right",
							isData: true,
							formatter: (el) => {
								return this.$options.filters.Number(el, { currency: "EUR" });
							},
						},
						{
							text: "Player ID",
							value: "player_mongo_id",
							align: "right",
							isData: true,
							shorten: 15,
						},
						{
							text: "Rounds",
							value: "round",
							align: "right",
							isData: true,
							formatter: (el) => {
								return this.$options.filters.Number(el, {
									style: undefined,
									currency: undefined,
									minimumFractionDigits: 0,
									maximumFractionDigits: 2,
								});
							},
						},
					],
					items: this.topSessions,
					on: {
						"click:row": this.sessionClick,
					},
					sort_by: "bet_sum",
					title: "Best Sessions",
				},
			];
		},
		filteredTableDefs() {
			return this.tableDefs.filter((el) => !el.hidden?.());
		},
	},
	mounted() {
		this.grid = window.GridStack.init({
			acceptWidgets: true,
			cellHeight: 20,
			column: 60,
			margin: 7,
			float: false,
			handle: ".grid-stack-handle",
			removable: ".odmrdavac",
			dragOut: false,
			disableResize: this.gridStop,
			disableDrag: this.gridStop,
		});
		// window.GridStack.setupDragIn(".grid-stack2", {
		// 	appendTo: "app",
		// 	helper: "clone",
		// });
		// this.grid.on("added", async (a, b, c, d) => {
		// 	if (b[0].id === this.lastAdded) return;
		// 	console.log("unguarded");
		// 	this.lastAdded = b[0].id + `${this.counter2}`;
		// 	await this.$nextTick();
		// 	this.grid.removeWidget(b[0].el);
		// 	await this.$nextTick();
		// 	this.additions.push({
		// 		title: "Avg Bet",
		// 		compareVal: 100,
		// 		value: 140,
		// 		h: b[0].h,
		// 		w: b[0].w,
		// 		x: b[0].x,
		// 		y: b[0].y,
		// 		id: b[0].id + `${this.counter2}`,
		// 		minW: b[0].minW,
		// 		minH: b[0].minH,
		// 	});
		// 	await this.$nextTick();
		// 	this.grid.makeWidget(`#${b[0].id + `${this.counter2}`}`);
		// 	await this.$nextTick();
		// 	this.counter2++;
		// 	this.grid.update();
		// });
		// this.grid.on("disable enable", async (a, b, c, d) => {
		// 	this.gridStop = a.type === "disable" ? true : false;
		// });
		this.sub(this.relTimeUpdater);
	},
	beforeDestroy() {
		this.unsub(this.relTimeUpdater);
	},

	watch: {
		currentCasino() {
			this.loadData(true);
		},
	},
};
</script>

<style lang="sass">

.green-glow
	width: 7px
	height: 7px
	border-radius: 100%
	background-color: var(--v-success-base)
	animation: breathing 1.5s ease-in infinite alternate

	@keyframes breathing
		0%
			box-shadow: 0 0 4px 2px green
		100%
			box-shadow: 0 0 4px 4px green

.red-glow
	width: 7px
	height: 7px
	border-radius: 100%
	background-color: var(--v-error-base)
	box-shadow: 0 0 4px 2px red

.transitionall
	transition: all .3s
.trash
	position: fixed
	z-index: 4
	left: 50%
	bottom: 0
	transform: translate(-50%,-50%)
	transition: all 0.5s ease-in-out

.trash.active
	bottom: 5%
	filter: drop-shadow(1px 1px 4px red)
	transition: all 0.5s ease-in-out


.grid-stack-item
	user-select: none

$columns: 60
@function fixed($float)
	@return calc(round($float * 1000) / 1000)
.gs-#{$columns} > .grid-stack-item
	width: fixed(calc(100% / $columns))

	@for $i from 1 through $columns - 1
		&[gs-x="#{$i}"]
			left: fixed((calc(100% / $columns)) * $i)
		&[gs-w="#{$i+1}"]
			width: fixed((calc(100% / $columns)) * ($i + 1))
</style>
