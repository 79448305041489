<template>
	<DashboardWidget
		:widget="widget"
		:enabled="enabled"
		:cls="def.cls">
		<v-card
			flat
			dense
			:class="def.cls"
			class="my-0 mx-0 align-center"
			style="height: 100%">
			<v-card-title>{{ def.title }}</v-card-title>
			<v-card-text class="px-1">
				<v-data-table
					v-on="_on"
					dense
					disable-sort
					:options="options"
					:item-class="itemClass"
					:sort-by="def.sort_by"
					:sort-desc="true"
					:footer-props="footerProps"
					hide-default-footer
					:headers="def.headers"
					:items="def.items">
					<template
						#item
						v-if="iLoading">
						<tr class="table-item">
							<td
								v-for="header in headers"
								:class="`text-${header.align}`"
								:key="header.value">
								<v-skeleton-loader type="text"></v-skeleton-loader>
							</td>
						</tr>
					</template>
					<template
						v-for="form of def.headers"
						#[`item.${form.value}`]="{ item }">
						<template v-if="form.shorten">
							<v-tooltip
								:key="`${form.value}-shorten`"
								:open-delay="250"
								:disabled="
									!shouldShortenString(item[form.value], 'e', form.shorten)
								"
								top>
								<template #activator="{ on, attrs }">
									<span
										v-on="on"
										v-bind="attrs">
										{{ item[form.value] | ellipsis("e", ".", form.shorten) }}
									</span>
								</template>
								<span>{{ item[form.value] }}</span>
							</v-tooltip>
						</template>
						<span
							:key="`${form.value}-noshorten`"
							v-else>
							{{
								form.formatter
									? form.formatter(item[form.value])
									: item[form.value]
							}}
						</span>
					</template>
					<template #[`item.no`]="{ index }">
						{{ index + 1 }}
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</DashboardWidget>
</template>

<script>
import table from "../../mixins/table";
import DashboardWidget from "./DashboardWidget.vue";
import { shouldShortenString } from "../../constants/helpers";
export default {
	components: { DashboardWidget },
	props: {
		def: {
			type: Object,
			default: () => ({}),
		},
		enabled: {
			type: Boolean,
			default: true,
		},
		iLoading: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			options: {
				itemsPerPage: 15,
			},
			footerProps: {
				"items-per-page-options": [15],
			},
		};
	},
	mixins: [table],
	methods: { shouldShortenString },
	computed: {
		rowIsClickable() {
			return Object.keys(this._on).includes("click:row");
		},
		_on() {
			if (typeof this.def.on !== "object") return {};
			if (Array.isArray(this.def.on)) return {};
			return this.def.on;
		},
		headers() {
			return this.def.headers;
		},
		widget() {
			return {
				id: this.def.id,
				layout: {
					w: this.def.w ?? 30,
					h: this.def.h ?? Math.ceil((32 * 18) / 20),
					x: this.def.x ?? undefined,
					y: this.def.y ?? undefined,
					minW: this.def.minW ?? undefined,
					minH: this.def.minH ?? undefined,
				},
			};
		},
	},
};
</script>
