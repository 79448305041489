<template>
	<div
		class="grid-stack-item"
		style="overflow: visible"
		ref="gridS"
		v-bind="gridStackAttributes">
		<div
			class="grid-stack-item-content"
			style="overflow: visible">
			<slot></slot>
			<div
				v-if="enabled"
				class="grid-stack-handle pa-3"
				style="position: absolute; right: 0px; top: 0px">
				<v-icon
					style="cursor: grab"
					size="30">
					mdi-drag
				</v-icon>
			</div>
			<v-tooltip
				v-if="$attrs.cls === 'pca'"
				color="warning"
				top>
				<template #activator="{ on, attrs }">
					<v-btn
						fab
						v-on="on"
						v-bind="attrs"
						height="20"
						width="20"
						color="warning"
						style="position: absolute; top: -10px; right: -10px">
						<v-icon size="15">mdi-help</v-icon>
					</v-btn>
				</template>
				<span>Warning</span>
			</v-tooltip>
			<v-tooltip
				v-if="$attrs.cls === 'kkd'"
				color="error"
				top>
				<template #activator="{ on, attrs }">
					<v-btn
						fab
						v-on="on"
						v-bind="attrs"
						height="20"
						width="20"
						color="error"
						style="position: absolute; top: -10px; right: -10px">
						<v-icon size="15">mdi-exclamation</v-icon>
					</v-btn>
				</template>
				<span>Error</span>
			</v-tooltip>
		</div>
	</div>
</template>
<script>
import get from "lodash/get";
export default {
	data() {
		return {
			obs: undefined,
		};
	},
	props: {
		widget: {
			type: Object,
			default: () => ({}),
		},
		enabled: {
			type: Boolean,
			default: true,
		},
	},
	mounted() {
		this.obs = new MutationObserver((mutations) => {
			this.$emit(
				"widgetResize",
				mutations.reduce((oldVal, newVal) => {
					oldVal[newVal.attributeName] = newVal.target.getAttribute(
						newVal.attributeName
					);
					return oldVal;
				}, {})
			);
		});
		this.obs.observe(this.$refs.gridS, {
			attributeFilter: ["gs-w", "gs-h", "gs-x", "gs-y"],
			attributeOldValue: true,
		});
	},
	computed: {
		gridStackAttributes() {
			return {
				id: get(this.widget, "id", "widget"),
				"gs-id": get(this.widget, "id", "widget"),
				"gs-x": get(this.widget, "layout.x", 0),
				"gs-y": get(this.widget, "layout.y", 0),
				// "gs-min-w": get(this.widget, "layout.minW", 6),
				// "gs-min-h": get(this.widget, "layout.minH", 5),
				"gs-w": get(this.widget, "layout.w"),
				"gs-h": get(this.widget, "layout.h"),
				"gs-size-to-content": true,
			};
		},
	},
};
</script>
<style lang="sass">
.grid-stack-placeholder
	border: 1px dashed var(--v-kajot-text-base)
	border-radius: 5px
.grid-stack-item
	transform: translate3d(0,0,0)
.kkd::before
	content: ""
	position: absolute
	top: 0px
	left: 0px
	width: 100%
	height: 100%
	border: 1px solid var(--v-error-base)
	opacity: 0.7
	border-radius: 4px

.kkd::after
	content: ""
	position: absolute
	top: 0px
	left: 0px
	width: 100%
	height: 100%
	opacity: 0.2
	background-color: var(--v-error-base)
	border-radius: 4px

.pca::before
	content: ""
	position: absolute
	top: 0px
	left: 0px
	width: 100%
	height: 100%
	background-color: var(--v-warning-base)
	opacity: 0.2
	border-radius: 4px

.pca::after
	content: ""
	position: absolute
	top: 0px
	left: 0px
	width: 100%
	height: 100%
	border: 1px solid var(--v-warning-base)
	opacity: 0.7
	border-radius: 4px

.v-progress-circular--indeterminate>svg
	animation-duration: 1.8s
	.v-progress-circular__overlay
		animation-duration: 1.8s

.v-progress-linear--visible .v-progress-linear__indeterminate--active .long, .v-progress-linear--visible .v-progress-linear__indeterminate--active
	.short, .long
		animation-duration: 1.8s
</style>
