var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mrditko", staticStyle: { "min-height": "90vh" } },
    [
      _c(
        "v-sheet",
        {
          staticClass: "d-flex align-center mx-4",
          attrs: { color: "transparent" },
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-chip",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                disabled: _vm.selectedTime !== 0,
                                color: "secondary",
                              },
                            },
                            "v-chip",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(" LIVE "),
                          _vm.selectedTime === -1
                            ? _c("div", {
                                staticClass: "ml-3 error--text red-glow",
                              })
                            : _c("div", {
                                staticClass: "ml-3 success--text green-glow",
                              }),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(
                  " Last Update: " +
                    _vm._s(
                      _vm.relTime == "now" ? _vm.relTime : `${_vm.relTime} ago`
                    ) +
                    " "
                ),
              ]),
            ]
          ),
          _c("v-spacer"),
          _c(
            "v-chip-group",
            {
              staticClass: "mt-1",
              attrs: { mandatory: "" },
              model: {
                value: _vm.selectedTime,
                callback: function ($$v) {
                  _vm.selectedTime = $$v
                },
                expression: "selectedTime",
              },
            },
            [
              _c(
                "v-chip",
                {
                  staticClass: "k-reset mx-1 transitionall",
                  attrs: {
                    small: _vm.isMobile,
                    "active-class": "primary",
                    value: -1,
                  },
                },
                [_vm._v(" Last " + _vm._s(_vm.timeframeName) + " ")]
              ),
              _c(
                "v-chip",
                {
                  staticClass: "k-reset mx-1 transitionall",
                  attrs: {
                    small: _vm.isMobile,
                    "active-class": "primary",
                    value: 0,
                  },
                },
                [_vm._v(" This " + _vm._s(_vm.timeframeName) + " ")]
              ),
            ],
            1
          ),
          _c("v-divider", {
            class: `${!_vm.isMobile ? `mx-7 my-3` : `mx-2 my-2`}`,
            attrs: { vertical: "" },
          }),
          _c(
            "v-chip-group",
            {
              staticClass: "mt-1",
              attrs: { mandatory: "" },
              model: {
                value: _vm.timeframe,
                callback: function ($$v) {
                  _vm.timeframe = $$v
                },
                expression: "timeframe",
              },
            },
            _vm._l(_vm.timeframes, function (t) {
              return _c(
                "v-chip",
                {
                  key: t[0],
                  staticClass: "k-reset mx-1 transitionall",
                  attrs: {
                    small: _vm.isMobile,
                    "active-class": "primary",
                    value: t[0],
                  },
                },
                [_vm._v(" " + _vm._s(t[1]) + " ")]
              )
            }),
            1
          ),
          _c("v-divider", {
            key: "compare-divider",
            class: `${!_vm.isMobile ? `mx-7 my-3` : `mx-2 my-2`}`,
            attrs: { vertical: "" },
          }),
          _c(
            "v-chip-group",
            {
              key: "compare-chips",
              staticClass: "mt-1",
              attrs: { mandatory: "" },
              model: {
                value: _vm.compareMode,
                callback: function ($$v) {
                  _vm.compareMode = $$v
                },
                expression: "compareMode",
              },
            },
            _vm._l(_vm.compareModes, function (c) {
              return _c(
                "v-chip",
                {
                  key: c,
                  staticClass: "k-reset mx-1 transitionall",
                  attrs: {
                    small: _vm.isMobile,
                    disabled: _vm.selectedTime != 0,
                    "active-class": "primary",
                    value: c,
                  },
                },
                [_vm._v(" " + _vm._s(c) + " ")]
              )
            }),
            1
          ),
          _vm.$attrs.fs ? _c("v-spacer") : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grid-stack mb-6 mx-2" },
        [
          _vm._l(_vm.smallsDef, function (def) {
            return _c("SmallEl", {
              key: def.id,
              attrs: { loading: _vm.loading, def: def, enabled: !_vm.gridStop },
            })
          }),
          _vm._l(_vm.filteredMidDefs, function (def) {
            return _c("MidEl", {
              key: def.id,
              attrs: {
                loading: _vm.loading,
                w: 60 / _vm.filteredMidDefs.length,
                def: def,
                enabled: !_vm.gridStop,
              },
              on: { elementLoading: _vm.elementLoading },
            })
          }),
          _vm._l(_vm.filteredGraphDefs, function (def) {
            return _c("GraphEl", {
              key: def.id,
              attrs: {
                loading: _vm.uiLoading,
                showTypeSwitch: def.type != "chart-pie",
                showLegend: def.type == "chart-pie",
                def: def,
                list: _vm.list,
                timeframe: _vm.timeframe,
                selectedTime: _vm.selectedTime,
                currentTimeFrameIndex: _vm.currentTimeFrameIndex,
                enabled: !_vm.gridStop,
              },
            })
          }),
          _vm._l(_vm.filteredTableDefs, function (def) {
            return _c("TableEl", {
              key: def.id,
              attrs: {
                iLoading: _vm.topsLoading,
                def: def,
                enabled: !_vm.gridStop,
              },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }